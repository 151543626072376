import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return <Box component="img" src="https://emb-website-new.s3.us-east-1.amazonaws.com/logo.svg" sx={{ width:150, ...sx }} />;
}
