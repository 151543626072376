import React, { useEffect } from "react";
import {
  Typography,
  Container,
  IconButton,
  Card,
  Box,
  Grid,
  Stack,
  Button,
  Paper,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { getBusinessProfileAsyncThunk } from "../../../store/slices/user/user.slice";
// components
import { green } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./BusinessDetail.theme";
import CustomVactor from "../../../components/NotFound/CustomVactor";
import { companyMapper } from "../../../utils/options";

export default function BusinessDetails({
  handleBusinessEditMode,
  setBusinessEditBtn,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getBusinessProfileAsyncThunk());
  }, [dispatch]);

  const businessData = useSelector(({ user }) => user.userData.business);

  return (
    <Grid container>
      {Object.keys(businessData).length > 0 ? (
        <Grid container>
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="flex-start"
            spacing={2}
            mb={2}
          >
            <Paper
              sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                Brand Name :{" "}
                <span style={{ fontWeight: "500" }}>
                  {businessData.brand_name}
                </span>
              </Typography>

              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                Registered Business Name :{" "}
                <span style={{ fontWeight: "500" }}>
                  {businessData.registered_business_name
                    ? businessData.registered_business_name
                    : "--"}
                </span>
              </Typography>

              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                Description :{" "}
                <span style={{ fontWeight: "500" }}>
                  {" "}
                  {businessData.description ? businessData.description : "--"}
                </span>
              </Typography>

              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                Type of Company :{" "}
                <span style={{ fontWeight: "500" }}>
                  {businessData.type_of_company
                    ? companyMapper[businessData.type_of_company]
                    : "--"}
                </span>
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                No of Employees :{" "}
                <span style={{ fontWeight: "500" }}>
                  {businessData.no_of_employees
                    ? businessData.no_of_employees
                    : "--"}
                </span>
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                Industry :{" "}
                <span style={{ fontWeight: "500" }}>
                  {businessData.industry ? businessData.industry : "--"}
                </span>
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                Website :{" "}
                <span style={{ fontWeight: "500" }}>
                  {" "}
                  {businessData.website ? businessData.website : "--"}
                </span>
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                Phone :{" "}
                <span style={{ fontWeight: "500" }}>
                  {" "}
                  {businessData.mobile_number
                    ? businessData.mobile_number
                    : "--"}
                </span>
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                Fax :{" "}
                <span style={{ fontWeight: "500" }}>
                  {businessData.fax ? businessData.fax : "--"}
                </span>
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                Sic Code :{" "}
                <span style={{ fontWeight: "500" }}>
                  {" "}
                  {businessData.sic_code ? businessData.sic_code : "--"}
                </span>
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                Annual Revenue :{" "}
                <span style={{ fontWeight: "500" }}>
                  {businessData.annual_revenue
                    ? businessData.annual_revenue
                    : "--"}
                </span>
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                PAN Number :{" "}
                <span style={{ fontWeight: "500" }}>
                  {" "}
                  {businessData.pan_no_of_account
                    ? businessData.pan_no_of_account
                    : "--"}
                </span>
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                GST Number :{" "}
                <span style={{ fontWeight: "500" }}>
                  {businessData.gst_no_of_account
                    ? businessData.gst_no_of_account
                    : "--"}
                </span>
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                Currency :{" "}
                <span style={{ fontWeight: "500" }}>
                  {" "}
                  {businessData.currency ? businessData.currency : "--"}
                </span>
              </Typography>
            </Paper>
          </Stack>
        </Grid>
      ) : (
        <Grid>
          <Grid item md={12} sm={12}>
            <CustomVactor />
          </Grid>
          <Grid item md={12} sm={12} textAlign="center" mt={4}>
            <Button
              variant="contained"
              onClick={() => {
                handleBusinessEditMode(true);
                setBusinessEditBtn("add");
              }}
            >
              Add Business
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
