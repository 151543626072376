import React from "react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

export default function CustomVactor({ width = "60%" }) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Box>
              <img
                src="/static/result-not-found.svg"
                style={{ width: width, margin: "0 auto" }}
                alt="not-found"
              />
            </Box>
            <Typography variant="h6">No Business Profile Found</Typography>
            <Typography variant="body1" sx={{ color: grey[500] }}></Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
